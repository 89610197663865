import { authenticate, authenticateWithCookie, logout } from "../components/utils/apiClient";

async function login(username: string, password: string): Promise<any> {
  try {
    const user = await authenticate(username, password);
    user.authdata = window.btoa(`${username}:${password}`);
    return user;
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
}

async function autoLogin(): Promise<any> {
  try {
    const user = await authenticateWithCookie();
    user.authdata = window.btoa(":");
    return user;
  } catch (error) {
    console.error("Auto-login failed:", error);
    throw error;
  }
}

function getAll(): Promise<any> {
  return fetch("https://localhost:5002/users").then(handleResponse);
}

function handleResponse(response: Response) {
  return response.json();
}

export const userService = {
  login,
  logout,
  autoLogin,
  getAll,
};
