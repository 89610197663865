import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import { Paper } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import qs from "qs";
import axios from 'axios';
import { IPriAdminResponseDTO } from "../interfaces/IPriAdminResponseDTO";
import { ISetIsPriAdminDTO } from "../interfaces/ISetIsPriAdminDTO";
import { IPriAdminDTO } from '../interfaces/IPriAdminDTO'
import { AuthContext } from "../App";
import ReactDOM from "react-dom";
// @ts-ignore
import { PriAdminCheckBox } from "./PRIAdminCheckBox";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from 'react-bootstrap/Button'
import { Collapse, Container, Row, Col } from 'reactstrap';
import { postRequest } from "./utils/apiRequests";


window.React = React;
window.ReactDOM = ReactDOM;
// @ts-ignore
window.PropTypes = PropTypes;

toast.configure();

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

  
  function descendingComparator(a: any, b: any, orderBy: any) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order: any, orderBy: any) {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array: any, comparator: any) {
    if(!array) return;
    const stabilizedThis = array.map((el: any, index: any,) => [el, index]);
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
  }
  
  const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Full Name' },
    { id: 'username', numeric: true, disablePadding: false, label: 'Username (e-mail address)' },
    { id: 'priAdmin', numeric: true, disablePadding: false, label: 'Is PRI Admin?' },
  ];
  
  function EnhancedTableHead(props: any) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property: any) => (event: any) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  interface IEnhancedToolBarProps {
    onSearchTermChanged: (searchTerm: string) => void
  }

  const EnhancedTableToolbar = (props: IEnhancedToolBarProps) => {
    const classes = useToolbarStyles();
    const { onSearchTermChanged } = props;

    return (
        <Toolbar style={{ backgroundColor: '#07aeef', color: 'white' }}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                PRI Users
            </Typography>
            <Tooltip title="Search">
                <IconButton aria-label="filter list">
                    <input type="text" style={{ fontSize: 20 }} onChange={e => onSearchTermChanged(e.target.value)} placeholder="  Type here to Search"></input>
                </IconButton>
            </Tooltip>
        </Toolbar>
    );
};
  
const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttonsContainer: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '& > :first-child': {
      marginLeft: 0
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

export function PRIAdmin(props: any) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('role');
    const [selected, setSelected] = React.useState<any>(null);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [priAdmin, setPriAdmin] = React.useState<IPriAdminResponseDTO>();
    const { state, dispatch } = React.useContext(AuthContext);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [userId, setUserid] = React.useState<any>();
    const [userGuid, setUserGuid] = React.useState<any>();
    const [isPriAdmin, setIsPriAdmin] = React.useState<any>('');
    const [value, setValue] = React.useState(0);
    const [openTnCsResetCheck, setOpenTnCsResetCheck] = React.useState(false);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, (priAdmin?.priAdmins?.length ?? 0) - page * rowsPerPage);

    useEffect(() => {
        if (state?.user.id && state.user.id > 0) {
          
            setUserid(state.user.id);
            setUserGuid(state.user.guid);
            getPriAdmin(state.user.guid);
        }
    }, [state?.user.id])

    useEffect(() => {
        let id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id
        let tab = qs.parse(props.location.search, { ignoreQueryPrefix: true }).tab
        console.log(id)

        if (tab && !isNaN(Number(tab)))
            setValue(Number(tab));

        if (id && +id > 0) {
            dispatch({ type: "SET_SIGNATORY_ID", payload: id });
            setUserid(id);
            getPriAdmin(id);
        }
    }, [])

    const getPriAdmin = async (id: any) => {
      //TODO: Need to include the requesting users auth token in every request (as this is used to validate permission to access this particular resource)
      const response = await axios.get<any>("/users?id=" + id);

      if (response === null) {
        props.history.goBack()
      }

      if(response !== null && response.data !== null && response.data !== ""){
        setPriAdmin(response.data);
      }
        
    }

    const resetAcceptedTnCs = async () => {
      const response = await postRequest<any>("/users/resetAcceptedTnCs");

      if(response) {
        var message = "Successfully reset users' T&Cs acceptance."
      } else {
        var message = "Failed to reset users' T&Cs acceptance."
      }
      showToast(message, response);
    }

    const showToast = (message: string, success: boolean) => {
      if(success){
        toast.success(message, {
          position: "bottom-right",
          autoClose: 3333,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
      else{
        toast.error(message, {
          position: "bottom-right",
          autoClose: 3333,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          })
      }
    };

    const handleRequestSort = (event: any, property: any) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleClick = (event: any, row: any) => {
      if(selected && row.email == selected.username){
        setSelected(null);
        return;
      }
      setSelected(row);
    };

    const handleResetTnCsClick = (event: any, doTnCsCheck: boolean) => {
      event.stopPropagation();

      if (doTnCsCheck) {
          setOpenTnCsResetCheck(true);
          return;
      } 
      else {
        setOpenTnCsResetCheck(false);
        resetAcceptedTnCs();
      }
  
    };
    const handleCloseResetAcceptedTnCs = (e: any) => {
      setOpenTnCsResetCheck(false);
    };

    const handleChangePage = (event: any, newPage: any) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handlePriAdminChange = async(isPriAdmin: boolean, row: any) => {

      let request : ISetIsPriAdminDTO = {
          Id: row.id,
          isPriAdmin: isPriAdmin
        };
      var result = await postRequest<boolean>("users/setIsPriAdmin", request);
      await getPriAdmin(userGuid);
      if(result){
        showToast("PRI Admin role updated", true);
        setIsPriAdmin(isPriAdmin);
      }
      else{
        showToast("An error occurred updating PRI Admin role", false);
      }
    };

    const handleSearchTermChanged = (searchTerm: string) => {
      setSearchTerm(searchTerm)
    };

    const handleFilter = (input: IPriAdminDTO) => {
      if(searchTerm.length > 2){
        if(input.fullName.toLowerCase().startsWith(searchTerm.toLowerCase())){
          return true;
        }

        if(input.username.toLowerCase().startsWith(searchTerm.toLowerCase())){
          return true;
        }
      }
      else{
        return input !== null;
      }
      return false;
    }

    return (
      <div className={classes.root}>
        
        <Paper className={classes.paper}>
          <>
            <EnhancedTableToolbar onSearchTermChanged={handleSearchTermChanged} />
            <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size='medium'
                  aria-label="enhanced table" 
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={priAdmin?.priAdmins?.length ?? 0}
                  />
                  <TableBody>
                    {priAdmin && priAdmin.priAdmins && stableSort(priAdmin?.priAdmins, getComparator(order, orderBy))
                      .filter((x: IPriAdminDTO) => handleFilter(x))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row: IPriAdminDTO, index: number) => {

                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row)}
                            role="checkbox"
                            tabIndex={-1}
                            key={row.fullName}
                          >
                            <TableCell padding="checkbox">
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              {row.fullName}
                            </TableCell>
                            <TableCell align="right">{row.username}</TableCell>
                                <TableCell align="right">


                                   
                              {row.id ? 
                                        (<PriAdminCheckBox row={ row } adminCheck={ true } handlePriAdminChange={ handlePriAdminChange }/>)
                                :
                                (<InputLabel>{row.isPriAdmin}</InputLabel>)}
                             
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={priAdmin?.priAdmins?.length ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          <Row>
            <Col style={{ textAlign: "right", paddingTop: 20 }}>
              <Button onClick={(e) => handleResetTnCsClick(e, true)}>Reset&nbsp;Accepted&nbsp;T&Cs</Button>
            </Col>
          </Row>
          <Dialog open={openTnCsResetCheck} onClose={handleCloseResetAcceptedTnCs} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Reset Accepted T&Cs?</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      By resetting the Terms and Conditions acceptance, all non-PRI users will have the reaccept the T&Cs.
                      Do you want to proceed? 
                </DialogContentText>

              </DialogContent>
              <DialogActions>
                  <Button onClick={handleCloseResetAcceptedTnCs} color="primary">
                      Cancel
                </Button>
                  <Button onClick={HandleResetAcceptedTnCs} color="primary">
                      Reset
                </Button>
              </DialogActions>
          </Dialog>
      </div>
    );
    
    function HandleResetAcceptedTnCs(e: any) {
      handleResetTnCsClick(e, false);
      handleCloseResetAcceptedTnCs(e);
    }
    
}
const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: "#333333",
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));