import axios, { AxiosRequestConfig } from "axios";

export async function postRequest<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
  try {
    const response = await axios.post<T>(url, data, config);
    return response.data;
  } catch (error) {
    console.error(`POST request to ${url} failed:`, error);
    throw error;
  }
}

//todo add get requests
//todo add put requests