import React, { useEffect, useContext } from "react";
import { userService } from "../services/userService";
import { AuthContext } from "../App";
import { useHistory } from "react-router-dom";
import {
  navigateToLogin,
  runLogoutTimer,
  setMainSignatoryFromUsernames,
} from "./utils/loginUtils";

const handleLogin = (user, dispatch, history, props) => {
  if (user !== null) {
    if (!user.authenticated) {
      dispatch({ type: "LOGOUT" });
      navigateToLogin(props);
    } else {
      dispatch({ type: "LOGIN", payload: user });

      runLogoutTimer(dispatch, 3600000);

      setMainSignatoryFromUsernames(user, dispatch);

      const { from = "/signatory" } = history.location?.state || {};
      const redirectPath =
        from === "/login" || from === "/dataportalv2" ? "/signatory" : from;

      props.history.push({
        pathname: redirectPath,
        state: { from: props.location.pathname },
      });
    }
  }
};

const loginFlow = (
  username,
  password,
  dispatch,
  history,
  props,
  isDevelopment
) => {
  const loginPromise = isDevelopment
    ? userService.login(username, password)
    : userService.autoLogin();

  loginPromise
    .then((user) => handleLogin(user, dispatch, history, props))
    .catch((error) => {
      console.error("Login error:", error);
      navigateToLogin(props);
    });
};

export function LoginPage(props) {
  const history = useHistory();
  const { dispatch } = useContext(AuthContext);
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_PASSWORD;
  const env = process.env.REACT_APP_ENVRIONMENT;
  const isDevelopment = env === "development";

  useEffect(() => {
    loginFlow(username, password, dispatch, history, props, isDevelopment);
  }, [username, password, dispatch, history, props, isDevelopment]);

  return <div>Loading...</div>;
}
