import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { IAcceptedTnCsDTO } from "../interfaces/IAcceptedTnCsDTO";
import { AuthContext } from "../App";
import { postRequest } from "./utils/apiRequests";

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  buttonsContainer: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& > :first-child": {
      marginLeft: 0,
    },
  },
}));

export function TermsAndConditions(props: any) {
  const { state, dispatch } = React.useContext(AuthContext);
  const css = `ol:not([className='navbar-nav']) { 
                        counter-reset: item 
                 }
                 ol li { 
                     display: block; 
                     padding: 10px 0 10px 20px;
                 }
                 ol li:before { 
                     content: counters(item, ".") " "; 
                     counter-increment: item; 
                     font-weight: bold; 
                     position: absolute; 
                     text-indent: -4em; 
                 }`;
  const handleTermsAndConditionsClick = async (
    id: any,
    accept: boolean,
    previousAccepted: boolean
  ) => {
    if (accept != previousAccepted) {
      let request: IAcceptedTnCsDTO = {
        Id: id,
        acceptedTnCs: accept,
      };
      state.user.acceptedTnCs = accept;
      console.log("request", request);
      var result = postRequest<boolean>("/users/setAcceptedTnCs", request,);
      dispatch({ type: "SET_USER_ACCEPTED_TNCS", payload: state.user });
    }
    props.history.push({
      pathname: `/dataportalv2`,
      state: "/termsandconditions",
    });
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <style>{css}</style>
      <div>
        <h1>Terms and Conditions</h1>
        <h2>PRI Data Portal</h2>
        <ol>
          <li>
            <b>AGREEMENT</b>
          </li>
          <ol>
            <li>
              This Agreement governs the use of PRI’s data portal (“Data
              Portal”) by signatories to the{" "}
              <a
                href="https://www.unpri.org/pri/what-are-the-principles-for-responsible-investment"
                target="_blank"
              >
                {" "}
                Principles for Responsible Investment
              </a>{" "}
              (“Signatories”) and their authorised representatives (“Authorised
              Users”). Signatories and/or their Authorised Users are also
              referred to in this Agreement as “User(s)”.
            </li>
            <li>
              This Agreement is a legally binding contract between PRI
              Association, a company incorporated in England under company
              number 07207947 (“PRI”) and the User.
            </li>
            <li>
              By using the Data Portal, the User confirms that they accept the
              terms and conditions set out herein and the PRI’s{" "}
              <a
                href="https://www.unpri.org/website-terms-of-use"
                target="_blank"
              >
                website terms of use
              </a>
              . The website terms of use are incorporated by reference into this
              Agreement, which means that the website terms of use form part of
              this Agreement. Users should also read the PRI’s{" "}
              <a href="https://www.unpri.org/privacy-policy" target="_blank">
                privacy policy
              </a>{" "}
              before using the Data Portal.
            </li>
            <li>
              Non-signatories may also be granted public access to the Data
              Portal. Non-signatories' use of the Data Portal is governed by the{" "}
              <a
                href="https://dwtyzx6upklss.cloudfront.net/uploads/l/s/e/publicuserguide_914408.pdf"
                target="_blank"
              >
                Public User Guide
              </a>
              .
            </li>
          </ol>
          <li>
            <b>ACCESSING THE DATA PORTAL</b>
          </li>
          <ol>
            <li>
              To access the Data Portal, you must have a login to a user account
              (“login”). To be eligible for a login, you must be a Signatory
              named contact or authorised by a Signatory named contact (the
              “eligibility requirements”).
            </li>
            <li>
              The PRI reserves the right to remove the login of a User who does
              not, in the PRI’s reasonable opinion, meet the eligibility
              requirements or if the Signatory associated with the login(s) is
              de-listed.
            </li>
            <li>
              Signatory named contacts may nominate one or more data portal
              administrators (“Data Portal Admin(s)”).
            </li>
          </ol>
          <li>
            <b>DATA PORTAL</b>
          </li>
          <ol>
            <li>
              The Data Portal is an online platform through which Users can
              access reports and insights about Signatories’ responsible
              investment practices.
            </li>
            <li>
              Through the Data Portal, a User may access, subject to the terms
              of this Agreement:
            </li>
            <ol>
              <li>
                A report showing the Signatory’s score for each indicator and
                module and how this score compares to its peers (a “Private
                Assessment Report”);
              </li>
              <li>
                A report presenting all core indicators, published in ranges if
                specified by the User, and responses (except for certain
                commercially sensitive indicators determined by PRI), “public
                indicators”, and indicators and responses that a Signatory has
                instructed PRI to publish, as more particularly described in
                clauses 5.3 and 5.4, relevant to a particular Signatory (a
                “Public Transparency Report”); and/or
              </li>
              <li>
                A report presenting all public and private indicators relevant
                to a particular Signatory, and such Signatory’s responses to
                those indicators (a “Private Transparency Report”).
              </li>
            </ol>
            <li>(each a “Report” and together, the “Reports”)</li>
            <li>
              All use of the Data Portal and the Reports is subject to the terms
              and conditions set out in this Agreement and, in particular, the{" "}
              <a
                href="https://ctp.unpri.org/termsandconditions#permitted-purposes"
                target="_blank"
              >
                Permitted Purposes
              </a>
              .
            </li>
          </ol>
          <li>
            <b>PERMITTED PURPOSES</b>
          </li>
          <ol>
            <li>
              The User shall and shall ensure that its Authorised Users shall
              only use the Data Portal for the following permitted purposes
              (together, the “Permitted Purposes”), to the extent that the
              relevant functionality is available within the Data Portal:
            </li>
            <ol>
              <li>Viewing the Signatory’s own Reports;</li>
              <li>Viewing public responses to indicators;</li>
              <li>
                Creating lists of Signatories based on criteria such as
                geography, AUM (assets under management) size, Signatory type,
                and keyword search functionality;
              </li>
              <li>
                Viewing the distribution of Signatory scores for all available
                peer groups (provided that the size of the peer group does not
                compromise the Signatory’s confidentiality);
              </li>
              <li>
                Viewing aggregated Signatory responses for all available peer
                groups (provided that the size of the peer group does not
                compromise the Signatory’s confidentiality);
              </li>
              <li>
                Using the{" "}
                <a
                  href="https://ctp.unpri.org/termsandconditions#pri-materials"
                  target="_blank"
                >
                  PRI Materials
                </a>{" "}
                subject to the limitations described in this Agreement; and
              </li>
              <li>
                Any other purposes notified to a User by PRI from time to time.
              </li>
            </ol>
          </ol>
          <li>
            <b>RIGHTS OF THE USER</b>
          </li>
          <ol>
            <li>
              Subject to the User’s and each Authorised User’s compliance with
              this Agreement, PRI hereby grants to the User and each Authorised
              User a non-exclusive, non-transferable, non-assignable licence to
              access and to use the{" "}
              <a
                href="https://ctp.unpri.org/termsandconditions#pri-materials"
                target="_blank"
              >
                PRI Materials
              </a>{" "}
              solely for the{" "}
              <a
                href="https://ctp.unpri.org/termsandconditions#permitted-purposes"
                target="_blank"
              >
                Permitted Purposes
              </a>{" "}
              and whilst the User is a Signatory or associated with a Signatory.
            </li>
            <li>
              Whether a given indicator is designated as “private” or “public”
              is determined through the functionality of the Online Reporting
              Tool (as defined below) and the related responses, and such
              designation will not be changed except where PRI has taken the
              decision to make certain indicators private for all Signatories.
            </li>
            <li>
              The User may share data comprising a Public Transparency Report
              (the “Public Data”) and the Private Reports obtained in accordance
              with this Agreement (together, the “Publishable Data”) with other
              organisations provided that the following conditions are met:
              <ol>
                <li>The Publishable Data is shared free of charge;</li>
                <li>
                  If the User intends to, or does, publish the Publishable Data
                  online via a portal, application or other such platform (a
                  “User Platform”) then the User shall ensure that:
                  <ol>
                    <li>
                      Before the Publishable Data is published on the User
                      Platform, the User obtains PRI’s prior written consent by
                      sending a request to{" "}
                      <a href="mailto:reporting@unpri.org">
                        reporting@unpri.org
                      </a>{" "}
                      and complies with PRI’s publishing guidelines given in
                      such consent, if any;
                    </li>
                    <li>
                      PRI and/or its appointed authorised representative(s) is
                      given all necessary access, at no cost to PRI, to the User
                      Platform for the purpose of auditing the User’s compliance
                      with this Agreement and other internal purposes, and the
                      User shall promptly on request provide PRI with all
                      necessary login and access credentials required for these
                      purposes;
                    </li>
                    <li>
                      Access to the Publishable Data via the User Platform is
                      not subject to additional charges, including licence
                      and/or download fees, over and above the fees normally
                      charged to access the User Platform;
                    </li>
                    <li>
                      The Publishable Data is not presented in a way, or
                      together with other materials, that is intended to and/or
                      reasonably likely to misinform, confuse or deceive any
                      person or to embarrass or bring PRI into disrepute;
                    </li>
                    <li>
                      Without prejudice to the rights granted under this
                      Agreement, the Private Reports and all private data
                      (including indicator responses) contained in the Private
                      Reports are not presented in a way, or provided with other
                      data (including Public Data), that may be attributed to
                      allow a specific Signatory to be identified;
                    </li>
                    <li>
                      Unless PRI instructs the User to do otherwise, PRI is
                      identified as the source of the Publishable Data in a
                      suitably obvious place such that it is clearly linked to
                      the published data, using the following wording, updated
                      as necessary to refer to the current year: “Copyright ©
                      2024 PRI Association. All rights reserved.”;
                    </li>
                    <li>
                      The User shall provide PRI with anonymised data concerning
                      the usage of the Publishable Data through the User
                      Platform (“Usage Data”) at least once every calendar year
                      and no later than 31 December in any given year;
                    </li>
                    <li>
                      The User shall ensure that the Usage Data is in a format
                      reasonably requested by PRI and sufficiently detailed to
                      allow PRI to extract insights by investor country, market,
                      investor type and size; and
                    </li>
                    <li>
                      The Publishable Data may not be downloaded on a
                      consolidated basis.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              The User may, with the prior written consent of PRI, use
              Publishable Data as part of a product and/or service offering
              (except that clause 3.2 applies to all User Platforms) provided
              that the User:
              <ol>
                <li>
                  Expressly states that the product and/or service offering and
                  the methods used to process the Publishable Data have not been
                  endorsed by PRI or any person representing PRI; and
                </li>
                <li>
                  The Private Reports and any and all private indicator
                  responses contained in the Private Reports are not presented
                  in a way, or provided with other data (including Public Data),
                  that may allow the private indicator responses of a specific
                  Signatory to be identified or attributed to a specific
                  Signatory.
                </li>
              </ol>
            </li>
            <li>
              The User may only share Private Reports of an asset manager
              (“Asset Manager Signatory”) with the User’s asset owner customers
              (a “User Customer”) if:
              <ol>
                <li>
                  The User is appointed as a provider of services to the User
                  Customer; and
                </li>
                <li>
                  The User Customer has appointed or intends to appoint the
                  Asset Manager Signatory to provide services, including the
                  management of assets, to the User Customer.
                </li>
              </ol>
            </li>
            <li>
              PRI will disclose all submitted Public Transparency Reports on the
              Data Portal and PRI’s website soon after each reporting cycle each
              year.
            </li>
          </ol>
          <li>
            <b>OBLIGATIONS OF THE USER</b>
          </li>
          <ol>
            <li>
              The User shall ensure that all of its Authorised Users are subject
              to a written contract containing appropriate confidentiality
              obligations that apply to the Reports.
            </li>
            <li>
              The User shall and shall ensure that each Authorised User shall:
              <ol>
                <li>
                  Keep secure and confidential all passwords and other
                  credentials used to access the Data Portal;
                </li>
                <li>
                  Notify PRI immediately if it knows or suspects that any third
                  party has unlawfully gained access to the Data Portal or the
                  data on the Data Portal or a Report;
                </li>
                <li>
                  Notify PRI immediately if it is inadvertently given access to
                  another Signatory’s landing page on the Data Portal or Private
                  Reports;
                </li>
                <li>
                  Ensure that each Authorised User is internally authorised to
                  access the Data Portal;
                </li>
                <li>
                  Ensure that any data exported from the Data Portal is stored
                  securely and, when shared internally, shared with appropriate
                  levels of confidentiality and marking such as confidential
                  folders with limited access;
                </li>
                <li>
                  Ensure that all devices used to access the Data Portal are
                  secure and password protected;
                </li>
                <li>
                  Ensure that its use of any disclosed Private Reports ceases at
                  the direction of the relevant Signatory and/or the removal of
                  access rights via the Data Portal;
                </li>
                <li>
                  Obtain and maintain all necessary licences, consents, and
                  permissions necessary for the User to provide and/or make
                  available any of its data, documents, and/or other materials
                  (“User Materials”) to PRI under this Agreement;
                </li>
                <li>
                  At all times comply with the terms of this Agreement,
                  particularly in relation to ensuring that it does not send,
                  knowingly take receipt of, upload, download, use or re-use any
                  material to which the applicable rights holder has not granted
                  the User the necessary permissions to do; and
                </li>
                <li>
                  Not access or use the Data Portal:
                  <ol>
                    <li>
                      To build a product or service which competes with the Data
                      Portal and/or the services that PRI provides;
                    </li>
                    <li>
                      In any unlawful manner, for any unlawful purpose, or to
                      act fraudulently or maliciously, for example, by hacking
                      into or inserting malicious code, including viruses, or
                      harmful data, into the Data Portal; and/or
                    </li>
                    <li>
                      In a way that could damage, disable, overburden, impair,
                      or compromise PRI’s systems or security or interfere with
                      other Signatories’ use of the Data Portal.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
          <li>
            <b>RIGHTS OF PRI</b>
          </li>
          <ol>
            <li>
              PRI may terminate and/or suspend access to the Data Portal at any
              time at its discretion including if a potential, alleged or
              existing threat to data security is identified, or, if in PRI’s
              opinion the User is or is likely to be in breach of this
              Agreement.
            </li>
            <li>
              The User grants PRI and its employees, agents, consultants,
              contractors, sub-contractors and other authorised representatives
              a non-exclusive and non-transferrable right to use the User
              Materials for and in connection with the purposes described in
              this Agreement.
            </li>
            <li>
              PRI may:
              <ol>
                <li>
                  As and when the applicable functionality becomes available on
                  the Data Portal, share Reports between Signatories subject to
                  the limitations described in clause 8 (Obligations of PRI);
                </li>
                <li>
                  Remove and/or erase all data related to the User from the Data
                  Portal and revoke all permissions applicable to Authorised
                  Users in the event that the User becomes de-listed as a
                  Signatory;
                </li>
                <li>
                  Use the User Materials, including as part of an analytic
                  exercise, to plan and implement improvements to the Data
                  Portal and other of PRI’s products and services and for
                  internal analysis and research purposes; and
                </li>
                <li>
                  Collect and use user statistics related to the Data Portal’s
                  functionalities, including most commonly searched, shared,
                  requested and viewed Reports, for any purposes as it sees fit.
                </li>
              </ol>
            </li>
          </ol>
          <li>
            <b>OBLIGATIONS OF PRI</b>
          </li>
          <ol>
            <li>
              PRI makes the Data Portal and the{" "}
              <a
                href="https://ctp.unpri.org/termsandconditions#reports"
                target="_blank"
              >
                Reports
              </a>{" "}
              contained therein available for use by the User and its Authorised
              Users on an “as is” and “as available” basis.
            </li>
            <li>
              Without prejudice to the foregoing, PRI will use reasonable
              endeavours to:
              <ol>
                <li>
                  As and when the applicable functionality becomes available on
                  the Data Portal, publish the indicators in Public Data and the
                  User’s responses to such indicators provided through the
                  Online Reporting Tool;
                </li>
                <li>
                  Process indicators in Private Reports and the User’s responses
                  to such indicators provided through the Online Reporting Tool
                  only in accordance with this Agreement and the terms of the
                  Online Reporting Tool unless authorised to do otherwise by the
                  Data Portal Admin;
                </li>
                <li>
                  Provide reasonable guidance on the access and usage of the
                  responses and peering scores; and
                </li>
                <li>
                  Without prejudice to clause 8.1, take reasonable steps to
                  address any technical problems and/or incidents in respect of
                  the Data Portal as soon as is reasonably practicable to do so.
                </li>
              </ol>
            </li>
            <li>
              The Data Portal is provided for the purposes of information only.
              The content of the Data Portal is not intended to be investment,
              legal, tax or any other form of advice, nor is it intended to be
              relied upon in making an investment or other decision. All content
              is provided with the understanding that the authors and publishers
              are not providing advice. PRI is not responsible for any errors or
              omissions, for any decision made or action taken based on
              information provided by the Data Portal or for any loss or damage
              arising from or caused by such decision or action.
            </li>
            <li>
              Subject to clause 13.1 (Liability) and except as described in this
              Agreement, PRI does not give any warranties, representations or
              other commitments to the User, including as to the functionality,
              performance, availability, transmission speeds, content, latency
              and/or accuracy of the Data Portal, and all warranties,
              conditions, representations, and terms whether written or oral,
              express or implied by statute, common law, custom, trade usage,
              course of dealing or otherwise are hereby excluded.
            </li>
          </ol>
          <li>
            <b>SHARING OF REPORTS AND INDICATORS</b>
          </li>
          <ol>
            <li>
              The User agrees that with respect to:
              <ol>
                <li>
                  Public indicators and the associated public responses (the
                  “Public Transparency Data”):
                  <ol>
                    <li>
                      Such Public Transparency Data will:
                      <ol>
                        <li>
                          Once the User is in a mandatory reporting year; or
                        </li>
                        <li>
                          With the User’s permission, if the User is a voluntary
                          discloser (i.e., subject to a reporting grace period),
                          be made publicly available in the form of a Public
                          Transparency Report and hosted on the PRI website and
                          Data Portal;
                        </li>
                      </ol>
                    </li>
                    <li>
                      PRI has the right to delay or to refuse publication of the
                      Public Transparency Report if PRI has reasonable reasons
                      to doubt the accuracy and/or veracity of the Public
                      Transparency Data;
                    </li>
                    <li>
                      PRI has the right to publish a shortened version of a
                      Public Transparency Report in order to change the
                      indicator-response structure but not the meaning of the
                      Public Transparency Data;
                    </li>
                    <li>
                      PRI may use such data in connection with PRI’s
                      accountability efforts including efforts to establish the
                      minimum criteria that can result in a de-listing of a
                      Signatory;
                    </li>
                    <li>
                      PRI is entitled to:
                      <ol>
                        <li>
                          Retain the Public Transparency Report and the Public
                          Transparency Data in its records;
                        </li>
                        <li>
                          Retain the Public Transparency Report and the Public
                          Transparency Data for re-analysis and re-publication
                          in part or in whole to third-party organisations;
                        </li>
                        <li>
                          Charge customers of PRI, including the User, a fee for
                          a dataset comprised of the Public Transparency Data of
                          the User and/or other Signatories; and
                        </li>
                        <li>
                          Present the Public Transparency Data in a visual
                          output;
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  Private Reports and the associated private indicators and
                  responses (the “Private Transparency Data”):
                  <ol>
                    <li>
                      PRI is entitled to:
                      <ol>
                        <li>
                          Retain the Private Transparency Data in its records;
                        </li>
                        <li>
                          Use an aggregate data set of all responses, including
                          the Private Transparency Data, for purposes aligned to
                          PRI’s objectives, including communicating externally
                          the state of responsible investment practices,
                          improving PRI’s reporting framework (“Reporting
                          Framework”), and implementing support tools and
                          assessment methodologies; and
                        </li>
                        <li>
                          Publish reports and/or analysis (including top-level
                          observations and asset class analyses based on
                          Signatories’ responses to both the public and private
                          indicators) and any such publication based on analysis
                          of the Private Transparency Data will result in
                          aggregate findings that do not, without the User’s
                          consent, highlight the User’s own responses.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  PRI shall ensure that Public Transparency Data and Private
                  Transparency Data will be handled in accordance with PRI’s
                  information security policy, which is available on request by
                  emailing{" "}
                  <a href="mailto:reporting@unpri.org">reporting@unpri.org</a>.
                </li>
              </ol>
            </li>
          </ol>

          <li>
            <b>PRI’S INTELLECTUAL PROPERTY RIGHTS</b>
          </li>
          <ol>
            <li>
              PRI and, where relevant, its licensors retain all rights,
              including all patents, know-how, trade secrets, trademarks,
              copyright, database and design rights, whether registered or not,
              and similar rights existing anywhere in the world (“IPR”) in:
              <ol>
                <li>
                  The questionnaire, indicators and other materials and
                  methodologies comprising PRI’s Reporting Framework;
                </li>
                <li>
                  The “Online Reporting Tool,” which is the platform through
                  which PRI collects responses from Signatories;
                </li>
                <li>The Data Portal;</li>
                <li>The Reports; and</li>
                <li>
                  Any derivative works and/or enhancements created from any or
                  all of the above, (the “PRI Materials”).
                </li>
              </ol>
            </li>
            <li>
              PRI has the right to terminate in whole or in part the licence to
              use the{" "}
              <a
                href="https://ctp.unpri.org/termsandconditions#pri-materials"
                target="_blank"
              >
                PRI Materials
              </a>{" "}
              described in clause 5.1 at its discretion.
            </li>
            <li>
              The User shall have no right or interest in the{" "}
              <a
                href="https://ctp.unpri.org/termsandconditions#pri-materials"
                target="_blank"
              >
                PRI Materials
              </a>{" "}
              except as described in this Agreement.
            </li>
          </ol>

          <li>
            <b>CONTRACTORS</b>
          </li>
          <ol>
            <li>
              PRI will be entitled to exercise any of its rights under the
              Agreement by appointing contractors provided that such contractors
              shall be subject to a written confidentiality agreement covering
              the matters described in this Agreement.
            </li>
          </ol>

          <li>
            <b>COMPLIANCE AND DATA PROTECTION</b>
          </li>
          <ol>
            <li>
              Both parties warrant that they:
              <ol>
                <li>
                  Will comply with all relevant data protection and privacy
                  laws, including the General Data Protection Regulation,
                  Regulation (EU) 2016/679, as it forms part of domestic law in
                  the United Kingdom by virtue of section 3 of the European
                  Union (Withdrawal) Act 2018; the Data Protection Act 2018 (and
                  regulations made thereunder); and the Privacy and Electronic
                  Communications Regulations 2003 (SI 2003/2426) (the “Privacy
                  Laws”) and in each case any updates to the same; and
                </li>
                <li>
                  Will not perform their obligations under this Agreement in
                  such a way as to cause the other party to this Agreement to
                  breach any of its applicable obligations under the Privacy
                  Laws.
                </li>
              </ol>
            </li>
          </ol>

          <li>
            <b>LIMITATION OF LIABILITY</b>
          </li>
          <ol>
            <li>
              Nothing in this Agreement shall limit or exclude either party's
              liability to the other to a greater extent than is permitted under
              applicable law for loss or damage resulting from:
              <ol>
                <li>Death or personal injury caused by negligence;</li>
                <li>Fraud or fraudulent misrepresentation; or</li>
                <li>
                  Any matter in respect of which losses may not be limited or
                  excluded under applicable laws.
                </li>
              </ol>
            </li>
            <li>
              Subject to clause 13.1, PRI shall not be liable to the User under
              any causes of action, whether such causes of action arise in
              contract (including under any indemnity or warranty), in tort
              (including negligence), for breach of statutory duty or otherwise
              (an “Action”) for loss or damage that comprise:
              <ol>
                <li>Loss or corruption of data or information;</li>
                <li>Special, indirect or consequential loss;</li>
                <li>Loss of profit or revenue;</li>
                <li>Loss of contract or business opportunity; or</li>
                <li>Depletion of goodwill,</li>
              </ol>
              in each case, whether arising directly or indirectly under or in
              connection with this Agreement and whether or not reasonably
              foreseeable, reasonably contemplatable, actually foreseen or
              actually contemplated by a party on its entering into this
              Agreement.
            </li>
            <li>
              Subject to clause 13.1 and clause 13.2, PRI’s total aggregate
              liability to the User in respect of all Actions arising under
              and/or in connection with this Agreement shall not exceed the sum
              of £1,000.00.
            </li>
            <li>
              The parties agree that the provisions of this clause 13 are
              considered by them to be reasonable in all the circumstances,
              having taken into account section 11 and the guidelines in
              schedule 2 of the Unfair Contract Terms Act 1977 and the nature of
              the Data Portal.
            </li>
            <li>
              The User shall indemnify, defend, and hold harmless PRI against
              any loss, cost or damage, including direct, indirect, special or
              consequential loss, relating to or arising directly or indirectly
              as a result of the inaccuracy of any data inputted by the User in
              the Online Reporting Tool or the use of any Reports by other
              Signatories to the extent that disclosure of such Reports to other
              Signatories has been authorised by the User.
            </li>
          </ol>

          <li>
            <b>GENERAL</b>
          </li>
          <ol>
            <li>
              Variation:
              <ol>
                <li>
                  PRI has sole discretion and control over, and may modify at
                  any time, with or without notice to the User, the
                  functionality, performance, configuration, appearance and
                  content of the Data Portal.
                </li>
                <li>
                  PRI may change the terms of the Agreement at any time by
                  notifying the User of such change at the time the User next
                  accesses the Data Portal, or by email.
                </li>
                <li>
                  The date that this Agreement was last updated is 25 November
                  2024.
                </li>
              </ol>
            </li>
            <li>
              Entire Agreement: This Agreement is the entire agreement between
              the parties in relation to a User’s use of the Data Portal.
            </li>
            <li>
              No Partnership: This Agreement is not intended to nor will it
              create any agency, partnership or joint venture. Neither party
              will hold itself out as being entitled to represent or bind the
              other party in any way.
            </li>
            <li>
              Governing Law and Jurisdiction: This Agreement and any dispute or
              claim arising out of or in connection with it, including
              non-contractual disputes or claims, shall be governed by and
              construed in accordance with English law. Each party irrevocably
              agrees that the courts of England and Wales shall have exclusive
              jurisdiction to settle any dispute or claim arising out of or in
              connection with this Agreement, including non-contractual disputes
              or claims.
            </li>
            <li>
              Notices:
              <ol>
                <li>
                  Notices from the User, except in connection with the
                  commencement of legal proceedings, must be given by email to
                  the appropriate address (
                  <a href="mailto:reporting@unpri.org">reporting@unpri.org</a>).
                </li>
                <li>
                  Notices from PRI to the User, except in connection with the
                  commencement of legal proceedings, will be given to the email
                  accounts through which the User has received the login for the
                  Data Portal unless the User provides in writing new email
                  accounts.
                </li>
              </ol>
            </li>
            <li>
              Third Party Rights: This Agreement does not confer any rights on
              any person or party, other than the parties to this Agreement,
              pursuant to the Contracts (Rights of Third Parties) Act 1999.
            </li>
            <li>
              No Waiver: No failure or delay by a party to exercise any right or
              remedy provided under this Agreement or by applicable law shall
              constitute a waiver of that or any other right or remedy, nor
              shall it prevent or restrict the further exercise of that or any
              other right or remedy.
            </li>
            <li>
              Severability: If any provision or part of a provision of this
              Agreement is found by any court or administrative body of
              competent jurisdiction to be invalid, unenforceable or illegal,
              the other provisions shall remain in force. If any invalid,
              unenforceable or illegal provision would be valid, enforceable or
              legal if some part of it were deleted, the provision shall apply
              with whatever modification is necessary to give effect to the
              commercial intention of the parties.
            </li>
            <li>
              Interpretation
              <p>In this Agreement:</p>
              <ol>
                <li>
                  The headings are for convenience only and shall not affect the
                  interpretation of the Agreement;
                </li>
                <li>
                  Any obligation in this Agreement on a party not to do
                  something, includes an obligation not to agree, allow, permit
                  or acquiesce to that thing being done;
                </li>
                <li>
                  Any reference in this Agreement to any enactment or statutory
                  provision or subordinate legislation will be construed as a
                  reference to it as from time to time replaced, amended,
                  consolidated or re-enacted, with or without modification, and
                  includes all orders, rules or regulations made under such
                  enactment;
                </li>
                <li>
                  Any list, word, or phrase following the words including,
                  include, in particular, for example, or any such similar
                  expression shall be construed as having the phrase without
                  limitation following them; and
                </li>
                <li>
                  The rule known as eiusdem generis shall not apply and
                  accordingly, words introduced by the word other shall not be
                  given a restrictive meaning by reason of the fact that such
                  words are preceded by words indicating a particular class of
                  acts, matters or things.
                </li>
              </ol>
            </li>
          </ol>
        </ol>
        <h2>
          <b>CONTACT US</b>
        </h2>
        <p>
          Questions and comments regarding these terms should be sent to{" "}
          <a href="mailto:reporting@unpri.org">reporting@unpri.org</a>. If you
          are having problems registering for an account or using the Data
          Portal, please contact{" "}
          <a href="mailto:helpdesk@unpri.org">helpdesk@unpri.org</a> for
          assistance.
        </p>
      </div>
      <div className={classes.buttonsContainer}>
        <Button
          className="button"
          variant="contained"
          color="primary"
          style={{ marginTop: -5 }}
          onClick={() =>
            handleTermsAndConditionsClick(
              state.user.id,
              true,
              state.user.acceptedTnCs
            )
          }
        >
          Accept
        </Button>

        <Button
          className="button"
          variant="contained"
          color="primary"
          style={{ marginTop: -5 }}
          onClick={() =>
            handleTermsAndConditionsClick(
              state.user.id,
              false,
              state.user.acceptedTnCs
            )
          }
        >
          Reject
        </Button>
      </div>
    </div>
  );
}
