import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from "react-router-dom";
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, StylesProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import { Button, CardContent, Checkbox, CircularProgress, Grid, Paper, Card, CardHeader } from '@material-ui/core';
import Form from 'react-bootstrap/Form';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import qs from "qs";
import axios from 'axios';
import { ISignatoryResponseDTO } from "../interfaces/ISignatoryResponseDTO";
import { ISetRoleRequestDTO } from "../interfaces/ISetRoleRequestDTO";
import { IUserRoleDTO } from '../interfaces/IUserRoleDTO';
import { AuthContext } from "../App";
import { ISetPermissionsResponseDTO } from "../interfaces/ISetPermissionsResponseDTO";
import ReactDOM from "react-dom";
// @ts-ignore
import { RoleCheckBoxes } from "./RoleCheckBoxes";
import { FullscreenExit } from "@material-ui/icons";
import { postRequest } from "./utils/apiRequests";


window.React = React;
window.ReactDOM = ReactDOM;
// @ts-ignore
window.PropTypes = PropTypes;

toast.configure();

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function descendingComparator(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: any, orderBy: any) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any, comparator: any) {
  if (!array) return;
  const stabilizedThis = array.map((el: any, index: any,) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Full Name' },
  { id: 'username', numeric: true, disablePadding: false, label: 'Username (e-mail address)' },
  { id: 'role', numeric: true, disablePadding: false, label: 'Role' },
];

function EnhancedTableHead(props: any) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface IEnhancedToolBarProps {
  onSearchTermChanged: (searchTerm: string) => void,
  signatoryName?: string 
  activeUsersCount?: number
}

const EnhancedTableToolbar = (props: IEnhancedToolBarProps) => {
  const classes = useToolbarStyles();
  const { onSearchTermChanged, signatoryName, activeUsersCount } = props;

  return (
    <Toolbar style={{ backgroundColor: '#07aeef', color: 'white' }}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        {signatoryName}
      </Typography>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div" align="right">
        Active Users: {activeUsersCount}
      </Typography>
      <Tooltip title="Search">
        <IconButton aria-label="filter list">
          <input type="text" style={{ fontSize: 20 }} onChange={e => onSearchTermChanged(e.target.value)} placeholder="  Search User "></input>
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttonsContainer: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '& > :first-child': {
      marginLeft: 0
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

export function Signatory(props: any) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('username');
  const [selected, setSelected] = React.useState<any>(null);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = React.useState(false);
  const [signatory, setSignatory] = React.useState<ISignatoryResponseDTO>();
  const { state, dispatch } = React.useContext(AuthContext);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [signatoryId, setSignatoryId] = React.useState<any>();
  const [guid, setGuid] = React.useState<any>();
  const [role, setRole] = React.useState<any>('');
  const [open, setOpen] = React.useState(false);
  const [showEditSurveyLink, setShowEditSurveyLink] = React.useState(false);
  const [revokeOpen, setRevokeOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [emailValid, setValidEmail] = React.useState(true);
  const [domainIsInvalid, setDomainIsInvalid] = React.useState(false);
  const [roleSelected, setRoleSelected] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const [surveyLink, setSurveyLink] = React.useState("");
  const [surveyLinkValidValid, setSurveyLinkValidValid] = React.useState(true);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, (signatory?.users?.length ?? 0) - page * rowsPerPage);
  const history = useHistory();

  // TODO USE EFFECT AS ONE FUNC
  useEffect(() => {
    let id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id
    let tab = qs.parse(props.location.search, { ignoreQueryPrefix: true }).tab
      ;

    if (tab && !isNaN(Number(tab)))
      setValue(Number(tab));

    if (id) {
      dispatch({ type: "SET_SIGNATORY_GUID", payload: id });
      setGuid(id);
      getSignatory(id);
    }
    else {
      if (state?.signatoryId && state.signatoryId > 0) {
        setSignatoryId(state.signatoryId);
        setGuid(state.guid);
        getSignatory(state.guid);
      }
    }
  }, [state?.signatoryId])


  // useEffect(() => {
  //   console.log("FIRST USE AFFECT")
  //     // console.table(state);
  //     if (state?.signatoryId && state.signatoryId > 0) {
  //         setSignatoryId(state.signatoryId);
  //         setGuid(state.guid);
  //         getSignatory(state.guid);
  //     }
  // }, [state?.signatoryId])



  const getSignatory = async (id: any) => {
    //TODO: Need to include the requesting users auth token in every request (as this is used to validate permission to access this particular resource)
    const response = await axios.get<any>("/signatorydetail?id=" + id);
    if (response.data == null) {

      if (props.location?.state) {

        if (props.location.state?.prevSignatoryId) {

          props.history.push({ pathname: '/survey', state: { from: props.location.pathname } })
        } else {

          const affiliatedSignatories = state?.user?.affiliatedSignatories;
          const managePermissionsSignatory = affiliatedSignatories.find((x: { role: number; }, y: { id: number; }) =>
            x.role == 1 && y.id != state?.signatoryId);
          if (managePermissionsSignatory) {

            const managePermissionsSignatoryId = managePermissionsSignatory.id;
            const managePermissionsSignatoryGuid = managePermissionsSignatory.guid;
            dispatch({ type: "SET_SIGNATORY_ID", payload: managePermissionsSignatoryId });
            dispatch({ type: "SET_SIGNATORY_GUID", payload: managePermissionsSignatoryGuid });
            props.history.push("/signatory?id=" + managePermissionsSignatoryGuid);
          } else {

            if (props.location.state?.internal) {
              props.history.push({ pathname: '/survey', state: { from: props.location.pathname, prevFrom: '/dataportalv2' } })
            } else {
              props.history.push({ pathname: '/survey', state: { from: props.location.pathname } })
            }
          }
        }
      } else {

        history.push({ pathname: '/survey', state: { from: props.location.pathname } })
      }
    }

    if (response !== null && response.data !== null && response.data !== "") {

      setSignatory(response.data);
      setSurveyLink(response.data.surveyLink);
    }
  }

  const showToast = (message: string, success: boolean) => {
    if (success) {
      toast.success(message, {
        position: "bottom-right",
        autoClose: 3333,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    else {
      toast.error(message, {
        position: "bottom-right",
        autoClose: 3333,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  };

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: any, row: any) => {
    if (selected && row.email == selected.username) {
      setSelected(null);
      return;
    }
    setSelected(row);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }; 

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };




  const handleClickShowEditSurveyLink = () => {
    console.log("show survey clicked");
    setShowEditSurveyLink(true);
    console.log(showEditSurveyLink);
  };

  const handleCloseEditSurveyLink = () => {
    setShowEditSurveyLink(false);
  };

  const handleClickOpen = () => {
    setRole('')
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRevokeClickOpen = () => {
    setRevokeOpen(true);
  };

  const handleRevokeClose = () => {
    setRevokeOpen(false);
  };

  const handleEditSurveyLink = async () => {


    const reg = new RegExp(/\b(https?|ftp|file):\/\/[\-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[\-A-Za-z0-9+&@#\/%=~_|]/);
    var valid = reg.test(surveyLink);

    if (!valid || surveyLink === null || surveyLink.length === 0) {
      setSurveyLinkValidValid(false);
      return;
    }

    const response = await axios.put<any>("/SignatoryDetail", { SignatoryId: guid, SurveyLink: surveyLink });

    console.log("put response", response);

    if (response.data === true) {
      toast.success("Survey link updated", {
        position: "bottom-right",
        autoClose: 3333,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setSurveyLinkValidValid(true);
      setShowEditSurveyLink(false);
    }
    else {
      toast.error("Error updating survey link", {
        position: "bottom-right",
        autoClose: 3333,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setShowEditSurveyLink(false);
    }
  }

  const handleInviteUser = async (checkEmailDomain: boolean) => {
    const reg = new RegExp(/^\S+@\S+$/);
    var valid = reg.test(email);
    if (!valid || !email) {
      setValidEmail(false);
      return;
    }
    setValidEmail(true);

    if (!role) {
      setRoleSelected(false);
      return;
    }
    setRoleSelected(true);

    setLoading(true)
    let request  = { emailAddress: email, role: role, signatoryId: state?.guid.toString(), permissionType: "grant", checkEmailDomainAllowed: checkEmailDomain }
    var result = await postRequest<ISetPermissionsResponseDTO>("/signatorydetail/setpermissions", request,);
    if (result.success) {

      if (result.emailFailed) {
        showToast(result.errorMessage, false);
      }
      else {
        showToast('E-mail invitation successfully sent to ' + email, true);
      }
      setOpen(false);
      await getSignatory(guid);
      setDomainIsInvalid(false);
    }
    else if ( result.errorMessage == "This user does not have a matching email domain to your organisation") {
      setDomainIsInvalid(true);
      setOpen(false);
    }
    else {
      showToast(result.errorMessage, false);
    }

    setLoading(false);
  }

  const handleRevokeUser = async () => {
    setLoading(true)
    let request = { emailAddress: selected.email, role: selected.role, signatoryId: state?.guid, permissionType: "revoke" }
    var result = await postRequest<ISetPermissionsResponseDTO>("/signatorydetail/setpermissions", request,);
    if (result.success) {

      if (result.emailFailed) {
        showToast(result.errorMessage, false);
        setRevokeOpen(false)
        await getSignatory(guid);
      }
      else {
        showToast('Revocation E-mail successfully sent to ' + selected.email, true);
        setRevokeOpen(false)
        await getSignatory(guid);
      }
    }
    else {
      showToast(result.errorMessage, false);
    }
    setLoading(false);
  }

  const handleRoleChanged = async (role: any, row: any) => {

    let request: ISetRoleRequestDTO = {
      userId: row.id,
      guid: guid,
      role: role.replace(new RegExp(' ', 'g'), ''),
      signatoryId: signatoryId
    };
    console.log("request", request);
    console.log("role", role);
    var result = await postRequest<boolean>("/signatorydetail/setRole", request,);
    await getSignatory(guid);
    if (result) {
      showToast("Role updated", true);
      setRole(role);
    }
    else {
      showToast("An error occurred updating role", false);
    }
  };



  function getPermittedAssignableRolesForUserType2(row: any, signatory: any, index: number) {


    return <>
      <Checkbox checked={true} name="contributor-checkbox" />
      <Checkbox checked={true} name="contributor-data-checkbox" />
      <Checkbox checked={true} name="admin-checkbox" />
      <Checkbox checked={true} name="main-admin-checkbox" />
    </>
  }

  const handleSearchTermChanged = (searchTerm: string) => {
    setSearchTerm(searchTerm)
  };

  const handleFilter = (input: IUserRoleDTO) => {
    if (searchTerm.length > 2) {
      if (input.fullName.toLowerCase().startsWith(searchTerm.toLowerCase())) {
        return true;
      }

      if (input.username.toLowerCase().startsWith(searchTerm.toLowerCase())) {
        return true;
      }
    }
    else {
      return input !== null;
    }
    return false;
  }

  const handleCancelInviteUser = (e: any) => {
    setDomainIsInvalid(false);
  };

  return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="USERS / ROLES" {...a11yProps(0)} />
            <Tab label="CHANGE HISTORY" {...a11yProps(1)} />

          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <>
            <EnhancedTableToolbar onSearchTermChanged={handleSearchTermChanged} signatoryName={signatory?.name} activeUsersCount={signatory?.users.length}/>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size='medium'
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={signatory?.users?.length ?? 0}
                />
                <TableBody>
                  {signatory && signatory.users && stableSort(signatory?.users, getComparator(order, orderBy))
                    .filter((x: IUserRoleDTO) => handleFilter(x))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: IUserRoleDTO, index: number) => {
                      const isItemSelected = selected && selected.username == row.username;
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.fullName}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.fullName}
                          </TableCell>
                          <TableCell align="right">{row.username}</TableCell>
                          <TableCell align="right">



                            {row.canUpdateRole ?
                              (<RoleCheckBoxes row={row} adminCheck={true} signatory={signatory} handleRoleChanged={handleRoleChanged} />)
                              :
                              (<InputLabel>{row.role}</InputLabel>)}

                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={signatory?.users?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <div className={classes.buttonsContainer}>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Invite User
            </Button>
            <Button variant="contained" color="primary" onClick={handleRevokeClickOpen} disabled={!selected}>
              Revoke Access
            </Button>
            {/* 
                    ############################################
                    Buttons to be reintroducted at a later stage
                    ############################################ 
                */}
            {/* <Button variant="contained" color="primary" disabled={true}>
                  Delete User
                </Button>
                <Button variant="contained" color="primary" disabled={true}>
                  Send Message
                </Button> */}
          </div>
          <div>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Invite User</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  To invite a user to join your organisation as an affiliate, enter their e-mail address below and choose the desired role from the drop down provided: -
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Email Address"
                  type="email"
                  value={email}
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)} />
                {!emailValid &&
                  <InputLabel style={{ color: 'red' }}>Please enter a valid email</InputLabel>}

                <InputLabel style={{ marginTop: 10 }} id="demo-simple-select-label">Role</InputLabel>
                <RoleCheckBoxes signatory={signatory} adminCheck={false} row={{ role: role }} handleRoleChanged={(selectedRole: any, selectedRow: any) => setRole(selectedRole)} />

                {!roleSelected &&
                  <InputLabel style={{ color: 'red' }}>Please select a role</InputLabel>}
                {loading &&
                  <CircularProgress />
                }
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={() => handleInviteUser(true)} color="primary">
                  Invite User
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={domainIsInvalid} onClose={handleCancelInviteUser} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Warning</DialogTitle>
              <DialogContent>
                <DialogContentText>
                <strong>The email domain of this user is different from others at your organisation. 
                  This user will have access to data your organisation reports to PRI, including any confidential information. Are you sure you want to invite {email}?</strong>
                </DialogContentText>

              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancelInviteUser} color="primary">
                  Cancel
                </Button>
                <Button onClick={() => handleInviteUser(false)} color="primary">
                  Invite User
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={revokeOpen} onClose={handleRevokeClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Revoke Access</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to revoke access of user {selected?.email} to Signatory {state?.selectedSignatoryId}
                </DialogContentText>
                {loading &&
                  <CircularProgress />
                }
              </DialogContent>
              <DialogActions>
                <Button onClick={handleRevokeClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleRevokeUser} color="primary">
                  Revoke User
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>E-Mail Address (username)</TableCell>
                  <TableCell align="left">Full Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Change Date</TableCell>
                  <TableCell align="left">Changed By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {signatory?.changeHistoryItems && signatory.changeHistoryItems.map((row: any) => (
                  <TableRow key={row.username}>
                    <TableCell component="th" scope="row">
                      {row.username}
                    </TableCell>
                    <TableCell align="left">{row.fullName}</TableCell>
                    <TableCell align="left">{row.description}</TableCell>
                    <TableCell align="left">{row.changeDate}</TableCell>
                    <TableCell align="left">{row.changedBy}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </div>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "#333333",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 200px',
  },
  info: {
    flex: '1 1 100%',
  },
}));