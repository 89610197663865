import { IAuthenticationResponseDTO } from './../../interfaces/IAuthenticationResponseDTO';
import { setAuthToken } from './authTokenUtils';
import { postRequest } from './apiRequests';
import { AxiosRequestConfig } from "axios";

export async function authenticate(username: string, password: string): Promise<IAuthenticationResponseDTO> {
  const param = { username, password };
  const token = setAuthToken(username, password);
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: token,
    },
    withCredentials: true,
  };

  try {
    return postRequest<IAuthenticationResponseDTO>("/users/authenticate", param, config);
  } catch (error) {
    console.error("Authentication failed:", error);
    throw error;
  }
}

export async function authenticateWithCookie(): Promise<IAuthenticationResponseDTO> {
  const token = setAuthToken("", "");
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: token,
    },
    withCredentials: true,
  };

  try {
    return postRequest<IAuthenticationResponseDTO>(
      "/users/authenticatecookie",
      config
    );
  } catch (error) {
    console.error("Authentication failed:", error);
    throw error;
  }
}

export async function logout(): Promise<IAuthenticationResponseDTO> {
  try {
    return postRequest<IAuthenticationResponseDTO>("/users/logout", {});
  } catch (error) {
    console.error("Logout failed:", error);
    throw error;
  }
}